<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-container>
            <v-img :src="require(`@/assets/UI-images/${imgName}`)" height="100px">
            </v-img>
            <v-card-title>
              <span class="headline">{{$t('dialogs.clone-manager.clone-manager')}}: {{copyObjectData.surname}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field v-model="clonedObject.surname" :label="$t('dialogs.clone-manager.manager')"
                                    clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete v-model="agencyId" :items="agencies" item-value="agencyId"
                                      :label="$t('dialogs.clone-manager.agency')"
                                      item-text="agencyName">
                      </v-autocomplete>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field v-model="clonedObject.position" :label="$t('dialogs.clone-manager.position')"
                                    clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <validation-provider :rules="{required:true,email:true}" v-slot="{ errors }">
                      <v-text-field v-model="clonedObject.email" :label="$t('dialogs.clone-manager.email')" clearable/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="clonedObject.mobilePhone" :label="$t('dialogs.clone-manager.mobile')"
                                  clearable/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="clonedObject.comment" :label="$t('dialogs.clone-manager.comments')"
                                  clearable/>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
                {{$t('buttons.cancel')}}
              </v-btn>
              <v-btn color="primary" v-on:click="$emit('clone-object',clonedObject)" :disabled="invalid">
                {{$t('buttons.clone')}}
              </v-btn>
            </v-card-actions>
          </v-container>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {email, required} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  extend('email', {
    ...email,
    message: 'Enter a valid e-mail'
  });

  export default {
    props: ['open-dialog', 'agencyId', 'agencies', 'manager'],
    name: "CloneManagerDialog",
    components: {ValidationObserver, ValidationProvider},
    data: () => ({
      clonedObject: [],
      imgName: 'cloneManagerDialog.jpg'
    }),
    computed: {
      copyObjectData() {
        return this.clonedObject = this.manager
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
