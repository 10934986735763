<template>
  <v-container id="managers" fluid tag="section">
    <CloneManagerDialog v-bind:open-dialog="cloneDialog" v-on:close-dialog="cloneDialog=false"
                        :agencyId="agency.agencyId"
                        :agencies="agencies" :manager="manager"
                        v-on:clone-object="cloneManager($event)"></CloneManagerDialog>
    <DeleteConfirmationDialog :object-name="'manager'" :manager="manager"
                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"
                              v-on:delete-object="deleteObject"></DeleteConfirmationDialog>
    <v-row justify="center" class="ma-auto">
      <v-col cols="12" md="8">
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col>
                <div class="display-2 font-weight-light" style="text-transform: uppercase">
                  <h4>{{manager.surname}}</h4>
                </div>
              </v-col>

              <v-col>
                <router-link :to="{ path: '/agency/' + agency.agencyId}">
                  <div class="display-2 font-weight-light"
                       style="float: right; text-transform: uppercase; color: white">
                    <h4>[{{agency.agencyName}} -- {{agency.city}}]</h4>
                  </div>
                </router-link>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="ma-auto">
              <v-col>
                <div class="subtitle-1 font-weight-light">
                  <a :href="`mailto:${manager.email}`" style="color: white">{{manager.email}}</a>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="subtitle-1 font-weight-light" style="float: right">
                  {{manager.mobilePhone}}
                </div>
              </v-col>
            </v-row>
            <v-row class="ma-auto">
              <v-col>
                <MenuDropdown></MenuDropdown>
              </v-col>
              <v-col>
                <ActionButtonGroup v-on:enable-edit="readonly=false" v-on:open-delete-dialog="openDeleteDialog()"
                                   v-on:clone-dialog="cloneDialog=true"></ActionButtonGroup>
              </v-col>
            </v-row>
          </template>

          <v-form>
            <v-container fluid>
              <v-row dense>
                <v-tabs grow icons-and-text center-active centered show-arrows>
                  <v-tab v-for="card in cards" :key="card.id">{{card.title}}
                    <v-icon :color="card.color">{{card.icon}}</v-icon>
                  </v-tab>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            :label="$t('manager-card.manager')" v-model="manager.surname" :readonly="readonly"/>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            :label="$t('manager-card.position')" v-model="manager.position" :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('manager-card.email')" v-model="manager.email" :readonly="readonly"/>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('manager-card.mobile')" v-model="manager.mobilePhone" :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12">
                          <v-textarea v-model="manager.comment" class="purple-input"
                                      :label="$t('manager-card.comments')"
                                      counter rows="2" :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('manager-card.created-date')" v-model="createdTime" readonly/>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('manager-card.modified-date')" v-model="modifiedTime" readonly/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>
                </v-tabs>
              </v-row>

              <v-row>
                <v-col cols="6" class="text-right">
                  <v-btn color="primary" class="mr-0" @click="disabled=true" block :loading="loading">
                    {{$t('buttons.cancel')}}
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn block color="primary" class="mr-0" @click="update" :disabled="readonly">
                    {{$t('buttons.update')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card
          class="v-card-profile" color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col>
                <div class="display-2 font-weight-light" style="text-transform: uppercase">
                  <h4>{{manager.surname}}</h4>
                  <h4>{{manager.position}}</h4>
                </div>
              </v-col>
            </v-row>
          </template>
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text" style="text-transform: uppercase">
              {{agency.agencyName}} -- {{agency.city}}
            </h6>
            <v-divider></v-divider>
          </v-card-text>

          <v-row justify="center" class="ma-auto">
            <v-expansion-panels popout focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>{{$t('manager-card.orders')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <OrderRelatedRecords :managerId="manager.managerId" :objectName="objectName"></OrderRelatedRecords>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import OrderRelatedRecords from "../../components/expansion-panels/OrderRelatedRecords";
  import ActionButtonGroup from "../../components/buttons/ActionButtonGroup";
  import MenuDropdown from "../dashboard/component/MenuDropdown";
  import CloneManagerDialog from "./CloneManagerDialog";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
  import {useAgencies} from "../../stores/agenciesStore";
  import {useManagers} from "../../stores/managersStore";
  import {mapState} from "pinia";

  const agencyStore = useAgencies()
  const managerStore = useManagers()

  let moment = require('moment');
  export default {
    name: "ManagerCard",
    components: {CloneManagerDialog, MenuDropdown, ActionButtonGroup, OrderRelatedRecords, DeleteConfirmationDialog},
    data() {
      return {
        cards: [
          {
            id: 1,
            title: this.$t('manager-card-tabs.main-info'),
            color: 'indigo',
            flex: 12,
            icon: 'mdi-folder-information-outline'
          },
          {
            id: 2,
            title: this.$t('manager-card-tabs.contact-details'),
            color: 'green',
            flex: 12,
            icon: 'mdi-card-account-phone-outline'
          },
          {
            id: 3,
            title: this.$t('manager-card-tabs.comments'),
            color: 'pink darken-3',
            flex: 12,
            icon: 'mdi-comment-processing-outline'
          },
          {
            id: 4,
            title: this.$t('manager-card-tabs.system-info'),
            color: 'purple darken-4',
            flex: 12,
            icon: 'mdi-table-cog'
          },
        ],
        manager: [],
        agency: [],
        cloneDialog: false,
        // panelNames: ['Orders'],
        objectName: 'isManager',
        readonly: true,
        disabled: true,
        files: [],
        createdTime: '',
        modifiedTime: '',
        fileCreatedTime: '',
        fileModifiedTime: '',
        deleteDialog: false,
      }
    },
    methods: {
      openDeleteDialog() {
        this.deleteDialog = true;
      },
      deleteObject() {
        let action = 'delete';
        let objectType = 'Manager';
        let params = new URLSearchParams();
        params.append('managerId', this.manager.managerId);
        AXIOS.delete('/api/deleteManager', {params})
          .then(response => {
            managerStore.deleteManager(this.manager.managerId)
            this.userNotification(response.data, action, objectType);
          })
        this.$router.push({name: 'All Managers'})
      },
      parseUnits() {
        let created = this.manager.createdTime;
        let modified = this.manager.modifiedTime;
        this.createdTime = moment(created).format("DD.MM.YYYY, HH:mm");
        this.modifiedTime = moment(modified).format("DD.MM.YYYY, HH:mm");
      },
      getEntities() {
        this.agency = this.manager.agency;
      },
      getManagerById() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        AXIOS.get('/api/manager/' + this.$route.params.managerId, {params})
          .then(response => {
            this.manager = response.data;
            this.getEntities();
            this.loading = false;
            this.parseUnits();
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      update() {
        this.loading = true;
        let action = 'update';
        let objectType = 'Manager';
        let manager = this.manager;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('managerId', manager.managerId);
        params.append('comment', manager.comment);
        params.append('position', manager.position);
        params.append('surname', manager.surname);
        params.append('email', manager.email);
        params.append('mobilePhone', manager.mobilePhone);
        AXIOS.post(`/api/updateManager`, params)
          .then(response => {
            managerStore.patchManager(response.data)
            this.userNotification(response, action, objectType);
            this.readonly = true;
          })
        this.loading = false;
      },
      cloneManager(clonedManager) {
        this.loading = true;
        let action = 'clone';
        let objectType = 'Manager';
        let params = new URLSearchParams();
        params.append('managerId', this.manager.managerId);
        params.append('surname', clonedManager.surname);
        params.append('agencyId', clonedManager.agency.agencyId);
        params.append('email', clonedManager.email);
        params.append('position', clonedManager.position);
        params.append('mobilePhone', clonedManager.mobilePhone);
        params.append('comment', clonedManager.comment);
        AXIOS.post(`/api/cloneManager`, params)
          .then(response => {
            managerStore.addManager(response.data);
            this.cloneDialog = false;
            this.userNotification(response, action, objectType);
            this.$router.push({path: '/manager/' + response.data.managerId})
          })
        this.loading = false;
      },
      updateNeededCheck() {
        if (!this.agencies.length) {
          agencyStore.getActiveAgencies()
        }
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
    },
    created() {
      this.getManagerById();
      this.updateNeededCheck()
    },
  }
</script>
<style scoped>

</style>
